.container {
  width: 100%;
  height: 100%;
  background-color: #ffdee9;
  background-image: linear-gradient(0deg, #ffdee9 0%, #b5fffc 100%);

  /*background-color: #85ffbd;
  background-image: linear-gradient(45deg, #85ffbd 0%, #fffb7d 100%);*/

  flex-direction: column;
  font-family: 'Rowdies', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: 'Rowdies', sans-serif;
  font-size: 4rem;
  text-align: center;
  margin-bottom: 20px;
}

.countdown {
  /*width: min-content;*/
  display: flex;
  gap: 20px;
  flex-direction: row;
  height: min-content;
}

.countdownCol {
  display: flex;
  flex-direction: column;
}

.countdownColElement strong {
  font-size: 3rem;
}
/*@media only screen and (max-width: 600px) {*/
/*    .countdown {*/
/*        flex-direction: column;*/
/*    }*/
/*}*/
